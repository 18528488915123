export const data = [
      {
            id: 1,
            title: "Optimise instore revenue",
            description: "Rent out your shelves on ThirdRetail today to earn additional cash for your day to day operations",
            image: "Factory.svg"
      }, 
      {     id: 2,    
            title: "Improve Underperforming shelves",
            description: "Identify and supercharge your shelves by replacing slow/underperforming products with high performing brands.",
            image: "Gauge.svg"
      },
      {
            id: 3,
            title: "Remove cross channel data blindspots",
            description: "As a Highstreet retail store, your biggest challenge is to access data points that drives your instore sales. Leverage ThirdRetail AI Co-Pilot in the coming days",
            image: "Footprints.svg"
      }
]