import { Link } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom'; 

export const Hero = () => {
      const navigate = useNavigate();

      const handleClick = () => {
            window.open('/request early access', '_blank'); // Open the form in a new tab
        };
      return (
        <section className="rent-text-container">

                        <div className="beta-message">
                              <div className="rent-beta-message">
                                    <span className="rent-new-notifier">
                                          NEW
                                    </span>
                                    Marketplace beta launched
                              </div>
                        </div>
                        <div className="rent-text-heading">
                              
                              <div className="text-heading-first">
                                    Rent a 
                                    <div className="text-highlight-shelf">
                                          <div className="rent-shelf-text">
                                                shelf
                                          </div>
                                          <div className="rent-shelf-smile-image">
                                                <img src="./hero-section/shelf-smile.png" alt="shelf"/>
                                          </div>

                                    </div>
                                    for
                              </div>

                              <div className="text-subheading">
                                    your products
                              </div>
                        </div>

                        <div className="rent-text-subheading">
                        Get your products in front of customers in the aisles of their favorite stores. It's like a pop-up shop, but
                         way more convenient.
                        </div>
                        <div className="rent-btn-search">


                              <div className="rent-synergy-btn">

                              <Link to = "Listyourshelf" className="rent-button-links">
                              I'm a retail store
                                    </Link>


     {/*}
                                    <div className="user_pop_wishlist" onClick={handleClick}>
                                   Request early access
                                    </div>
 */ }
                             

                                    <Link to="Synergy" className="rent-button-links"  style={{background : "#09A7B3"}}>
                                    I'm a digital first brand
                                    </Link>





                              </div>
                        </div>



        </section>
    )
}

