import React from 'react'
import { useState } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';

const Synergydatatile5 = () => {
 
  const locations = ['London', 'Manchester', 'Birmingham','Liverpool' ,"Others"];
 // const locations = ['London', 'Manchester', 'Glasgow','Birmingham','Brighton','Leeds','Liverpool','Yorkshire','Bath','EdinBurgh'];
    const [selectedOptionslocation, setSelectedOptionslocation] = useState([]);
  
    const handleCheckboxChangelocation = (location) => {
      if (selectedOptionslocation.includes(location)) {
        setSelectedOptionslocation(selectedOptionslocation.filter((selected) => selected !== location));
      } else {
        setSelectedOptionslocation([...selectedOptionslocation, location]);
      }
    };
     sessionStorage.setItem('selectedOptionslocation' ,selectedOptionslocation) ; 
    
     // hisrtypically
      const selectedOptions = sessionStorage.getItem('selectedOptions');
      const selectedProducts = sessionStorage.getItem('selectedProducts');
      const selectedOptionsage = sessionStorage.getItem('selectedOptionsage');
      const selectedOptionsprice = sessionStorage.getItem('selectedOptionsprice');
      const selectedOptionslocations = sessionStorage.getItem('selectedOptionslocation');

       const synergyData = `${selectedOptions} , ${selectedProducts} ,${selectedOptionsage}  ,${selectedOptionsprice} ,${selectedOptionslocations}` ;
    

       // Prepare the string for GraphQL. This step might not be necessary if your values don't include special GraphQL characters.
       const preparedSynergyData = synergyData.replace(/"/g, '\\"').replace(/\n/g, '\\n');
       
     
       const mutationQuery = `
       mutation {
         setUserJourney(
           ecommUserId: null,
           userPath: "${preparedSynergyData}",
           userId: null
         ) {
           userPath
           ecommUser {
             FirstName
           }
         }
       }
     `;
     
     // Send the mutation query to the backend using Axios
     axios({
       url: 'https://api.thirdretail.com/', // Replace with your actual GraphQL endpoint
       method: 'post',
       headers: {
         'Content-Type': 'application/json',
         // Include any other headers your endpoint requires
       },
       data: {
         query: mutationQuery,
       }
     }).then((response) => {
       console.log('Response from backend:', response.data);
       // Handle the response data here
     }).catch((error) => {
       console.error('Error sending mutation:', error);
       // Handle the error here
     });
      
  return (
    <div className='synergydatatile1'>

<div className="synergydataleft">

        
<div className="logo_summary">
<div className="synergy_icon_left_top">
<Link to="/">
<img src="/synergy_logo.svg" alt="Synergy Logo" />
</Link>
</div>
<div className="synergy_text">Create the most affordable omnichannel experience for your product.</div>
</div>
<div className="synergy_insight_section">
<div className="synergy_insight_data">
<div className="side_img_synergy_insight">
            <div className="synergy_insight_percentage">222%</div>
            <div className="img_animation_section">
         <img className="animated-image_insight top-right"  src="/icon_insight_1.svg" alt=""  />
         <img src="/icon_insight_2" alt="" className="animated-image_insight top-right" />
         <img src="/icon_insight_1" alt="" className="animated-image_insight top-right" />
         </div>
        </div> 
  <div className="synergy_insight_data_text">increase in customer acquisition cost in last 8 years to acquire a customer online (Credits : Forbes)</div>
  <div className="synergy_insight_controller">
    <div className="synergy_insight_controller_left"></div>
    <div className="synergy_insight_progressbar"><img src ="/progessbar_5.svg" alt ="" /></div>
    <div className="synergy_insight_controller_right"></div>
    </div>
  </div>

</div>



</div>
    
      <div className="synergydataright">
          <div className="arrayofline">
          <div className="line1synergyyes"> </div>
          <div className="line1synergyyes"> </div>
          <div className="line1synergyyes"> </div>
          <div className="line1synergyyes"> </div>
          <div className="line1synergyyes"> </div>
          </div>

        <div className="synergyrightfind">FIND YOUR SYNERGY</div>
        <div className="synergytell">What is your preferred location preferences ?</div>
       


                   <div className='fourtsynergyoption'>
                   {locations.map((location, index) => (
                   <label
                   key={index}
                   className={`checkbox-container ${selectedOptionslocation.includes(location) ? 'selected' : ''}`}
                   style={{ display: 'flex', marginLeft: "1px" }}
                   >
                      <input
                           type="checkbox"
                           style={{ marginRight: '5px', borderRadius: '50%' }}
                           checked={selectedOptionslocation.includes(location)}
                           onChange={() => handleCheckboxChangelocation(location)}
                       />
                   {location}
                   </label>
                   ))}
 
</div>
</div>
    </div>
  )
}

export default Synergydatatile5
